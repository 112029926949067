import Inputmask from "inputmask";
import EmailValidator from "email-validator";

Inputmask.extendDefinitions({ A: { validator: "[1-9]" } });
const phoneInputmask = new Inputmask({
  mask: "(A99) 999 99 99",
  onincomplete: function () {
    this.value = "";
  },
});
const GRECAPTCHA_KEY = "6LeH99wpAAAAAKl32B9-XUDw40UtsJecG-DdAsX8";

async function getRecaptchaToken() {
  // await grecaptcha.ready();
  const token = await grecaptcha.execute(GRECAPTCHA_KEY, {
    action: "submit",
  });
  return token;
}

window.test_recaptcha = getRecaptchaToken;

function checkValidationErrors(form, showMessages) {
  const validation_containers = form.querySelectorAll(
    '[data-validation="container"]',
  );
  let error_count = 0;
  validation_containers.forEach((c) => {
    const input = c.querySelector('[data-validation="input"]'),
      error = c.querySelector('[data-validation="error"]');
    if (input.hasAttribute("data-validation-required")) {
      let input_value = "";
      if (input.tagName === "SELECT") {
        input_value = input.options[input.selectedIndex].value;
      } else {
        input_value = input.value;
      }
      if (input_value.length > 0) {
        error.innerText = "";
      } else {
        if (showMessages) {
          error.innerText = input.dataset.validationRequired;
        }
        error_count++;
        return;
      }
    }

    if (input.hasAttribute("data-validation-email")) {
      if (EmailValidator.validate(input.value)) {
        error.innerText = "";
      } else {
        if (showMessages) {
          error.innerText = input.dataset.validationEmail;
        }
        error_count++;
        return;
      }
    }
  });
  return error_count;
}

function updateSubmitDisabled(form, consent, submit) {
  if (!consent.checked) {
    submit.disabled = true;
    return;
  }
  const errors = checkValidationErrors(form, false);
  // console.log('errors: ', errors);
  if (0 < errors) {
    submit.disabled = true;
    return;
  }
  submit.disabled = false;
}

/**
 *
 * @param {HTMLFormElement} form
 * @param {string} target
 * @param {Function} beforeCallback
 * @param {Function} successCallback
 * @param {Function} failCallback
 * @param {Function} alwaysCallback
 */
async function formAjax(
  form,
  target,
  beforeCallback,
  successCallback,
  failCallback,
  alwaysCallback,
) {
  beforeCallback();
  const formData = new FormData(form);
  const response = await fetch(target, {
    method: "POST",
    body: formData,
  }).then((r) => r.json());
  if (response.success) {
    successCallback(response);
  } else {
    failCallback(response);
  }
  alwaysCallback(response);
}

export {
  phoneInputmask,
  getRecaptchaToken,
  checkValidationErrors,
  updateSubmitDisabled,
  formAjax,
};
