window.openDrawer = function (drawer_id) {
  const drawer_element = document.getElementById(drawer_id);
  if (!drawer_element) {
    return;
  }
  document.body.classList.add("body-tailwind-drawer-is-open");
  drawer_element.classList.remove("tailwind-drawer-closed");
  drawer_element.classList.add("tailwind-drawer-open");
};

window.closeDrawer = function (drawer_id) {
  const drawer_element = document.getElementById(drawer_id);
  if (!drawer_element) {
    return;
  }
  document.body.classList.remove("body-tailwind-drawer-is-open");
  drawer_element.classList.add("tailwind-drawer-closed");
  drawer_element.classList.remove("tailwind-drawer-open");
};

document.addEventListener("DOMContentLoaded", () => {
  document
    .querySelectorAll(
      '[data-tailwind-drawer="drawer"] [data-tailwind-drawer="close"]',
    )
    .forEach((drawer_close_button) => {
      drawer_close_button.addEventListener("click", (e) => {
        const drawer = drawer_close_button.closest(
          '[data-tailwind-drawer="drawer"]',
        );
        window.closeDrawer(drawer.getAttribute("id"));
        drawer.dispatchEvent(new CustomEvent("close.tw.drawer"));
      });
    });
});
