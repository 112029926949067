document.addEventListener("DOMContentLoaded", () => {
  const custom_dropdowns = document.querySelectorAll(".js-custom-dropdown");
  const setCustomDropdownLabel = (d) => {
    const span = d.querySelector(".js-custom-dropdown-span"),
      select = d.querySelector(".js-custom-dropdown-select");
    span.innerText = select.options[select.selectedIndex].value;
  };
  custom_dropdowns.forEach((dropdown) => {
    setCustomDropdownLabel(dropdown);
    dropdown
      .querySelector(".js-custom-dropdown-select")
      .addEventListener("change", () => {
        setCustomDropdownLabel(dropdown);
      });
  });
});
