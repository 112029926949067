import {
  checkValidationErrors,
  updateSubmitDisabled,
  getRecaptchaToken,
  formAjax,
} from "./form-common";

document.addEventListener("DOMContentLoaded", () => {
  // show drawer
  /*const url = new URL(document.location.href);
  if (url.searchParams.get("test") === "ebulletin") {
    setTimeout(() => {
      window.openDrawer("drawer-ebulletin");
    }, 5000);
  }*/

  window.ebulletinTimeoutId = 0;
  const HALF_MINUTE = 30000,
    ONE_MINUTE = 60000;
  const showEbulletinModal = () => {
    window.openDrawer("drawer-ebulletin");
  };
  const clearAndSetEbulletinTimeout = () => {
    window.clearTimeout(window.ebulletinTimeoutId);
    window.ebulletinTimeoutId = window.setTimeout(
      showEbulletinModal,
      HALF_MINUTE,
    );
  };
  document
    .getElementById("drawer-ebulletin")
    .addEventListener("close.tw.drawer", () => {
      // console.log("drawer-ebulletin close");
      window.localStorage.setItem("ebulletin_shown", true);
    });
  if (window.localStorage.getItem("ebulletin_shown") !== "true") {
    window.setTimeout(() => {
      clearAndSetEbulletinTimeout();
      document.addEventListener("keydown", clearAndSetEbulletinTimeout);
      document.addEventListener("scroll", clearAndSetEbulletinTimeout);
      document.addEventListener("mousemove", clearAndSetEbulletinTimeout);
    }, ONE_MINUTE);
  }

  const bulletin_form = document.querySelector("#bulletin-form"),
    bulletin_form_response = document.querySelector("#bulletin-form-response"),
    bulletin_consent = bulletin_form.querySelector("#bulletin_consent"),
    bulletin_submit = bulletin_form.querySelector('button[type="submit"]'),
    bulletin_titles = document.querySelectorAll(".bulletin-titles");
  if (!bulletin_form) {
    return;
  }
  // validation
  const e_bulletin_updateSubmitDisabled = () => {
    updateSubmitDisabled(bulletin_form, bulletin_consent, bulletin_submit);
  };

  bulletin_form.addEventListener("change", e_bulletin_updateSubmitDisabled);
  bulletin_form.addEventListener("keyup", e_bulletin_updateSubmitDisabled);

  bulletin_form.addEventListener("submit", async (e) => {
    e.preventDefault();
    const error_count = checkValidationErrors(bulletin_form, true);
    if (error_count > 0) {
      return;
    }

    try {
      const bulletin_token = await getRecaptchaToken();
      bulletin_form.querySelector("#bulletin_token").value = bulletin_token;
    } catch (error) {
      alert("E-bulletin is failed");
      return;
    }

    // ajax
    // result
    formAjax(
      /* form */ bulletin_form,
      /* target */ "server/ebulletin.php",
      /* beforeCallback */ () => {
        bulletin_form.style.display = "none";
        bulletin_titles.forEach((bulletin_title) => {
          bulletin_title.classList.add("tw-hidden");
        });
        bulletin_form_response.classList.remove("tw-hidden");
        bulletin_form_response.innerText = "Please wait...";
      },
      /* successCallback */ (response) => {
        bulletin_form.reset();
      },
      /* failCallback */ () => {
        window.setTimeout(() => {
          bulletin_form_response.innerText = "";
          bulletin_form_response.classList.add("tw-hidden");
          bulletin_form.style.display = "";
          bulletin_titles.forEach((bulletin_title) => {
            bulletin_title.classList.remove("tw-hidden");
          });
        }, 5000);
      },
      /* alwaysCallback */ (response) => {
        bulletin_form_response.innerText = response.message;
      },
    );
  });
});
