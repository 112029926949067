document.addEventListener("DOMContentLoaded", () => {
  const backToTop = document.querySelector("#back-to-top");
  if (!backToTop) {
    return;
  }
  backToTop.addEventListener("click", () => {
    window.scrollTo(0, 0);
  });
  let scrollSetTimeoutBackToTopId;
  function scrollTimeoutBackToTopFn() {
    if (window.pageYOffset > 100) {
      backToTop.classList.remove("tw-invisible");
    } else {
      backToTop.classList.add("tw-invisible");
    }
  }
  window.addEventListener("scroll", () => {
    window.clearTimeout(scrollSetTimeoutBackToTopId);
    scrollSetTimeoutBackToTopId = window.setTimeout(
      scrollTimeoutBackToTopFn,
      70,
    );
  });
});
