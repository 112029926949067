import {
  phoneInputmask,
  checkValidationErrors,
  updateSubmitDisabled,
  getRecaptchaToken,
  formAjax,
} from "./form-common";

document.addEventListener("DOMContentLoaded", () => {
  const contact_form = document.querySelector("#contact-form"),
    contact_modal = document.querySelector("#contact_modal"),
    consent = contact_form.querySelector("#consent"),
    submit = contact_form.querySelector('button[type="submit"]');
  if (!contact_form) {
    return;
  }

  const contact_form_updateSubmitDisabled = () => {
    updateSubmitDisabled(contact_form, consent, submit);
  };

  // validation
  contact_form.addEventListener("change", contact_form_updateSubmitDisabled);
  contact_form.addEventListener("keyup", contact_form_updateSubmitDisabled);

  // inputmask
  phoneInputmask.mask('[type="tel"]');

  contact_form.addEventListener("submit", async (e) => {
    e.preventDefault();
    const error_count = checkValidationErrors(contact_form, true);
    if (error_count > 0) {
      return;
    }

    try {
      const token = await getRecaptchaToken();
      contact_form.querySelector("#token").value = token;
    } catch (error) {
      alert("Form sending is failed.");
      return;
    }

    // ajax
    // result
    formAjax(
      /* form */ contact_form,
      /* target */ "server/form.php",
      /* beforeCallback */ () => {
        window.showModal("contact_modal");
        contact_modal.classList.add("tailwind-modal-deny-close");
        const modal_body = contact_modal.querySelector(".tailwind-modal-body");
        modal_body.innerHTML = `
          <span id="form-response" class="tw-block tw-mb-3">Please wait...</span>
          <button class="tailwind-modal-close-button tw-block tw-px-5 tw-py-2 tw-rounded-lg tw-text-sm tw-bg-atoll tw-border tw-border-atoll tw-text-white tw-transition-colors tw-duration-300 hover:tw-bg-white hover:tw-text-atoll">OK</button>
        `;
      },
      /* successCallback */ (response) => {
        if ("dataLayer" in window) {
          window.dataLayer.push({
            event: "generate_lead",
            ...response,
          });
        }
        contact_form.reset();
      },
      /* failCallback */ () => {},
      /* alwaysCallback */ (response) => {
        document.getElementById("form-response").innerText = response.message;
        contact_modal.classList.remove("tailwind-modal-deny-close");
      },
    );
  });
});
