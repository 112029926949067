window.showModal = (modal_id) => {
  const modal_element = document.getElementById(modal_id);
  if (!modal_element) {
    return;
  }
  document.body.classList.add("body-tailwind-modal-is-open");
  modal_element.classList.add("tailwind-modal-show");
};

document.addEventListener("DOMContentLoaded", () => {
  const modal_buttons = document.querySelectorAll("[data-modal]");
  modal_buttons.forEach((modal_button) => {
    modal_button.addEventListener("click", () => {
      // alert(modal_button.dataset.modal);
      window.showModal(modal_button.dataset.modal);
    });
  });

  const modals = document.querySelectorAll(".tailwind-modal");
  modals.forEach((m) => {
    m.addEventListener("click", (event) => {
      if (
        (event.target.classList.contains("tailwind-modal") &&
          event.target.classList.contains("tailwind-modal-show") &&
          !event.target.classList.contains("tailwind-modal-deny-close")) ||
        event.target.classList.contains("tailwind-modal-close") ||
        event.target.classList.contains("tailwind-modal-close-button")
      ) {
        m.querySelector(".tailwind-modal-body").scrollTo(0, 0);
        m.classList.remove("tailwind-modal-show");
        document.body.classList.remove("body-tailwind-modal-is-open");
        m.dispatchEvent(new CustomEvent("close.tw.modal"));
      }
    });
  });
});
