document.addEventListener("DOMContentLoaded", () => {
  let scrolled = false;
  window.addEventListener("scroll", () => {
    scrolled = true;
  });
  setInterval(() => {
    if (!scrolled) {
      return;
    }
    document.querySelectorAll(".beacon-parallax-parent").forEach((bp) => {
      if (window.scrollY + window.innerHeight < bp.offsetTop) {
        return;
      }

      bp.querySelectorAll(".beacon-parallax").forEach((b) => {
        b.style.setProperty(
          "--tw-translate-y",
          "-" +
            ((window.scrollY - bp.offsetTop + b.offsetTop) /
              window.innerHeight) *
              40 +
            "%",
        );
      });
    });
    scrolled = false;
  }, 1000 / 20);
});
